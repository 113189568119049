.popup-imagen {  
    height: 500px;
    width: 600px;
    float: left;
   }
   .popup-segmento-titulo {  
    background: #333;
    text-align: left;
    color:  #aaaaaa;
    width: 100%;
    float: left;
  }  
  .popup-titulo {
    margin-left: 5px;
    font-weight: bold;
    float: left;
  }
  .popup-segmento-header {  
    text-align: left;
    width: 100%;
    color:  #aaaaaa;
    background: #333
  } 
  .popup-header {
    margin-left: 5px;
    text-align: left;
    font-weight: bold;
  } 
  